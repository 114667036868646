/**
 * AM2 Slider
 * @author Bican Marian Valeriu
 * @version 0.1
 */
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay]);

export default (function (am2) {
	const { Component } = am2;
	const getDefaults = {
		autoHeight: true,
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		autoplay: {
			delay: 5000,
		  },
	};

	class Slider extends Component {
		/**
		 * Construct Animate instance
		 * @constructor
		 * @param {Element} el
		 * @param {Object} options
		 */
		constructor(el, options) {
			super(Slider, el, options);
			this.el = el;
			this.el.Slider = this;
			/**
			 * Options for the animation
			 * @member Slider#options
			 */
			this.options = Object.assign({}, Slider.defaults, options);
			Slider.elements.push(this);
			this.setupEventHandlers();
			this.build();
		}

		static get defaults() {
			return getDefaults;
		}

		static init(els, options) {
			return super.init(this, els, options);
		}

		/**
		 * Get Instance
		 */
		static getInstance(el) {
			const domElem = el.jquery ? el[0] : el;
			return domElem.Slider;
		}

		/**
		 * Setup Events
		 */
		setupEventHandlers() {
			return null;
		}

		/**
		 * Remove Event Handlers
		 */
		removeEventHandlers() {
			if (Slider.getInstance(this.el)) {
				return null;
			}
		}

		removeClasses() {
			return 	null;
		}

		/**
		 * Teardown component
		 */
		destroy() {
			if (Slider.getInstance(this.el)) {
				this.removeClasses();
				this.setupEventHandlers();
				const index = Slider.elements.indexOf(this);
				Slider.elements.splice(index, 1);
				this.el.Slider = undefined;
			}
		}

		/**
		 * Build
		 */
		build() {
			new Swiper(this.el, this.options);
			return;
		}
	}

	/**
	 * @static
	 * @memberof Slider
	 */
	Slider.elements = [];
	am2.Slider = Slider;

}).apply(this, [window.am2]);
